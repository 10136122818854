import React from 'react';
// import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import Button from 'components/UI/Button';
import Typography from 'components/UI/Typography';
import Link from 'components/UI/Link';
import ThemeLink from 'components/UI/ThemeLink';
import BackLink from 'components/UI/BackLink';
import Container from 'components/Container';
import { StyleguideContainer } from 'components/Pages/Styleguide/styles';

const Styleguide = (props) => {
  const { location, path } = props;

  return (
    <div>
      <Container containerBackgroundColor={'transparent'} spaced={true}>
        <StyleguideContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Icon type="loading-logo" className="Icon-Loading" />
            <br />
            <Icon type="header-logo" />
            <br />
            <Icon type="right-arrow" />
            <br />
            <Icon type="external-link" />
            <br />
            <Icon type="plus" />
            <br />
            <Icon type="minus" />
            <br />
            <Icon type="left-arrow-ellipse" />
            <br />
            <Icon type="right-arrow-ellipse" />
            <br />
            <Icon type="phone" />
            <br />
            <Icon type="clock" />
            <br />
            <Icon type="facebook" />
            <br />
            <Icon type="instagram" />
            <br />
            <Icon type="twitter" />
            <br />
            <Icon type="messenger" />
            <br />
            <Icon type="link" />
            <br />
            <Icon type="download" />
            <br />
            <Icon type="globe" />
            <br />
            <Icon type="fr-en" />
            <br />
            <Icon type="en" />
            <br />
            <Icon type="multilingual" />
            <br />
            <Icon type="external-phone" />
            <br />
            <Icon type="burger" />
            <br />
            <Icon type="burger-close" />
            <br />
            <Icon type="fab" />
            <br />
            <Icon type="24/7" />
            <br />
            <br />
            <br />
            <Button theme="primary" disabled={true}>
              Primary Button
            </Button>
            <br />
            <Button theme="secondary" disabled={true}>
              Secondary Button
            </Button>
            <br />
            <Button
              theme="primary"
              className="Button-Phone"
              withIcon="phone"
              to="tel:1 800 845-3453"
            >
              1 800 845-3453
            </Button>
            <br />
            <Button
              theme="secondary"
              className="Button-Phone"
              withIcon="phone"
              to="tel:1 800 845-3453"
            >
              1 800 845-3453
            </Button>
            <br />
            <Button
              theme="primary"
              className="Button-External"
              withIcon="external"
              link={true}
              to="/"
              isExternal={false}
            >
              Primary Button - Transition Link
            </Button>
            <br />
            <Button
              theme="secondary"
              className="Button-External"
              withIcon="external"
              to="/"
              isExternal={true}
              openInNewTab={true}
            >
              Secondary Button External
            </Button>
            <br />
            <Button
              theme="primary"
              className="Button-Download"
              withIcon="download"
            >
              Primary Button Download
            </Button>
            <br />
            <Button
              theme="secondary"
              className="Button-Download"
              withIcon="download"
            >
              Secondary Button Download
            </Button>
            <br />
            <ThemeLink
              children="J’aimerais aider quelqu’un"
              theme="secondary"
            />
            <br />
            <ThemeLink
              to="/"
              children="J’aimerais aider quelqu’un"
              theme="secondary"
              bordered={false}
              topSpacingMobile="20"
              bottomSpacingMobile="20"
            />
            <br />
            <div style={{ padding: '10px', backgroundColor: 'lightblue' }}>
              {/* just a back link */}
              <BackLink />
              <br />
            </div>
            <br />
            <br />
            <br />
            <div
              style={{
                width: '100%',
                background: 'rgba(0, 0,0,0.05)',
                padding: 30,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            />
            <div className={'typography'} style={{ marginTop: 30 }}>
              <div>
                <Link to="/">Internal Link</Link>
              </div>
              <div>
                <Link to="https://www.google.com" target="_blank">
                  External Link
                </Link>
              </div>
              <div>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    alert('custom function');
                  }}
                >
                  Custom Function Link
                </Link>
              </div>
              <Typography theme="h1">Heading 1</Typography>
              <Typography theme="h2">Heading 2</Typography>
              <Typography theme="h3">Heading 3</Typography>
              <Typography theme="h4">Heading 4</Typography>
              <Typography theme="h5">Heading 5</Typography>
              <Typography theme="h6">Heading 6</Typography>
              <Typography theme="body-xl">
                BODY XL - Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Egestas eu diam vel morbi curabitur fermentum, euismod.
              </Typography>
              <Typography theme="body-l">
                BODY L - Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Egestas eu diam vel morbi curabitur fermentum, euismod.
              </Typography>
              <Typography theme="body">
                BODY -Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Egestas eu diam vel morbi curabitur fermentum, euismod.
              </Typography>
              <Typography theme="body-s">
                BODY S - Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Egestas eu diam vel morbi curabitur fermentum, euismod.
              </Typography>
              <Typography theme="eyebrow-text">
                EYEBROW TEXT - Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Egestas eu diam vel morbi curabitur fermentum,
                euismod.
              </Typography>
              <Typography theme="article-body">
                ARTICLE BODY - Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Egestas eu diam vel morbi curabitur fermentum,
                euismod.
              </Typography>
            </div>

            <ul>
              <li>Unordered List Item 1</li>
              <li>Unordered List Item 2</li>
              <li>Unordered List Item 3</li>
              <li>Unordered List Item 4</li>
              <li>Unordered List Item 5</li>
              <li>Unordered List Item 6</li>
              <li>
                Unordered List Item 7
                <ul>
                  <li>Unordered List Item 1 - Level 2</li>
                  <li>Unordered List Item 2 - Level 2</li>
                  <li>Unordered List Item 3 - Level 2</li>
                </ul>
              </li>
              <li>Unordered List Item 8 - Level 2</li>
              <li>Unordered List Item 9 - Level 2</li>
            </ul>
            <br></br>
            <ol>
              <li>Ordered List Item 1</li>
              <li>Ordered List Item 2</li>
              <li>Ordered List Item 3</li>
              <li>Ordered List Item 4</li>
              <li>Ordered List Item 5</li>
              <li>Ordered List Item 6</li>
              <li>
                Ordered List Item 7
                <ol>
                  <li>Ordered List Item 1 - Level 2</li>
                  <li>Ordered List Item 2 - Level 2</li>
                  <li>Ordered List Item 3 - Level 2</li>
                </ol>
              </li>
              <li>Ordered List Item 8</li>
              <li>Ordered List Item 9</li>
              <li>Ordered List Item 10</li>
            </ol>
          </div>
        </StyleguideContainer>
      </Container>
    </div>
  );
};

// Styleguide.propTypes = {};

export default Styleguide;
